.uploads {
  padding: 20px;
  box-sizing: border-box;
  margin: 20px;
  font-family: "Inter", sans-serif;
}

h2 {
  font-size: 2em;
  color: #444444;
  margin-top: 40px;
  margin-bottom: 20px;
}

p {
  font-size: 1.2em;
  color: #666666;
  margin-bottom: 30px;
}

a {
  color: #008cba;
  text-decoration: none;
  font-size: 1.2em;
}

a:hover {
  color: #005d79;
}

.upload-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 60px;
  justify-items: center;
  align-items: center;
}
