/* Navigation */
nav ul {
  list-style: none;
}

nav li {
  margin-bottom: 15px;
}

nav a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
}

nav a:hover {
  color: #66ccff;
}

/* Sidebar container */
.sidebar-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
  background-color: #333;
  padding: 20px;
}

/* Company logo */
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
}

.logo img {
  max-width: 80%;
  max-height: 80%;
}

/* Menu item */
.menu-item {
  display: flex;
  align-items: center;
}

/* Menu item icon */
.menu-item-icon {
  margin-right: 10px;
}

/* Footer */
.footer {
  margin-top: auto;
  font-size: 10px;
  color: #999;
  text-align: center;
  padding: 10px;
}

/* Active menu item */
.active {
  color: #007bff;
  font-weight: bold;
}

/* AuthButton */
button {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

/* account info */
.accountinfo {
  font-size: 12px;
  color: #fff;
  text-align: left;
  padding: 10px;
}

.custom-line {
  border: 0;
  height: 1px;
  background: #606060; /* Change this to your desired line color */
}
