.account {
  margin: 20px;
  padding: 20px;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.account button {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.accountcontactsupport p {
  font-size: 0.2em;
  color: #000000;
  margin-bottom: 30px;
}
