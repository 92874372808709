.royaltiesdash {
  margin: 20px;
  padding: 20px;
  width: 50%;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.rd-quicklinks-head h3 {
  align-items: center;
}

.rd-nav {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
}

.rd-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rd-menu-item {
  margin: 0;
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

.rd-menu-link {
  color: #333;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease;
}

.rd-menu-link:hover {
  border-bottom: 2px solid #333;
}

/* Style the payment notice section */
.rd-payment-notices {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rd-payment-notice-item {
  background-color: #f2f2f2;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.rd-payment-notice-link {
  display: block;
  padding: 20px;
  text-decoration: none;
  color: #333;
}

.rd-payment-notice-title {
  font-size: 20px;
  margin: 0;
  padding: 10px 20px;
  background-color: #fff;
}

.rd-payment-notice-text {
  margin: 10px 20px;
  font-size: 14px;
}

/* Style the Stripe Express section */
.rd-stripe-express {
  text-align: center;
  margin-bottom: 40px;
}

.rd-stripe-express-img {
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  height: auto;
}
