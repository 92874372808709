.home {
  margin: 20px;
  padding: 20px;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  max-width: 800px;
}

.home h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.home h2 {
  font-size: 28px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.home h3 {
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.home p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.home ol {
  counter-reset: item;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.home li {
  position: relative;
  margin-bottom: 30px;
  padding-left: 40px;
}

.home li a {
  display: inline-block;
  margin-left: 10px;
  font-size: 20px;
  color: #888;
}

.home li a:hover {
  color: #333;
}

.home li a i {
  font-style: normal;
}

.home li:before {
  content: counter(item);
  counter-increment: item;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 24px;
  font-weight: bold;
  color: #888;
}

.home ol ol li:before {
  content: counters(item, ".") ". ";
}

.home ol ol {
  margin-top: 10px;
  margin-bottom: 10px;
}

.home ol ol li {
  margin-bottom: 10px;
  padding-left: 20px;
}

.home ol ol li:before {
  font-size: 18px;
  font-weight: bold;
  color: #888;
}
